import React, { useState, useEffect } from 'react';
import './css/style.css';
import './css/header.css';
import './css/sidebar.css';
import './css/searchbar.css';
import './css/login.css';
import './css/fields.css';
import './config/custom.css';
import './config/bootstrap.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/** pages */
import Login from './pages/login/index';
import Recover from './pages/login/recover';
import Reset from './pages/login/reset';
import Logout from './pages/user/logout';
import Settings from './pages/user/settings';
import NotFound from './pages/not_found';
import Home from './pages/index';
import License from './pages/license';
import Entity from './pages/entity/index';
import EntityAdd from './pages/entity/add';
import EntityEdit from './pages/entity/edit';
import EntityClone from './pages/entity/clone';
import EntityItem from './pages/entity/item';
import Page from './pages/page/index';
import NlPage from './pages/nlpage/index'; //not-logged page
import LoginSocial from './pages/login-social/index';


/** core & components */
import { LanguageProvider } from './core/language-provider';
import { LoginManager } from './core/login-manager';
import HeaderBlock from './components/BodyElements/HeaderBlock';
import FooterBlock from './components/BodyElements/FooterBlock';
import ConnectionStatus from './components/BodyElements/ConnectionStatus';


import { Helmet } from "react-helmet";
import { settings } from './config/settings';



function App() {

    library.add(fas)

    const [ isLogged, setIsLogged ] = useState(null);


    useEffect(() => {
        async function init() {
            var response = await LoginManager.checkLogin()
            console.log('checkLogin', response)
            setIsLogged(response)
        }

        init()
        detectSidChange()
    }, []);

    async function detectSidChange() {
        var sessionId = LoginManager.registerChangeSid(async function () {
            var response = await LoginManager.checkLogin()
            console.log('CheckLogin after sid changed', response)
            setIsLogged(response)
        })
        console.log('--- SESSION ID ' + sessionId + ' ---')
    }


    


    if (isLogged === null) {
        return (
            <>
                <p>Loading...</p>
                <ConnectionStatus />
            </>
        )
    }
    else if (isLogged === false) {
        return (
            <Router basename={'/'} >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{settings.title}</title>
                    <meta name="description" content="React application" />
                </Helmet>
                <div id='app-container'>
                    <Routes>
                        <Route exact path='/' element={<Login />} /> {/* default page */}
                        <Route exact path='/recover' element={<Recover />} />
                        <Route exact path='/reset' element={<Reset />} />
                        <Route path='/license' element={<License />} />
                        <Route path="/nl_page/:page" element={<NlPage />} />
                        <Route exact path='/login-social' element={<LoginSocial />} />
                        <Route path="*" element={<NotFound />} /> {/* 404 page */}
                    </Routes>
                </div>
                <ToastContainer 
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" 
                />
                <ConnectionStatus />
            </Router>
        )
    }
    else {
        return (
            <Router basename={'/'}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{settings.title}</title>
                    <meta name="description" content="React application" />
                </Helmet>
                <HeaderBlock />
                <div id='column-container' className='sidebar-open'>
                    <div id='app-container'>
                        <Routes>
                            <Route exact path='/' element={<Home />} /> {/* default page */}
                            <Route path="*" element={<NotFound />} /> {/* 404 page */}
                            <Route path='/license' element={<License />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path='/settings' element={<Settings />} />
                            <Route path="/entity/:entity" element={<Entity />} />
                            <Route path="/entity/:entity/add" element={<EntityAdd />} />
                            <Route path="/entity/:entity/edit" element={<EntityEdit />} />
                            <Route path="/entity/:entity/clone" element={<EntityClone />} />
                            <Route path="/entity/:entity/item" element={<EntityItem />} />
                            <Route path="/page/:page" element={<Page />} />
                            <Route path="/nl_page/:page" element={<NlPage />} />
                        </Routes>
                    </div>
                </div>
                <FooterBlock />
                <ToastContainer 
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light" 
                />
                <ConnectionStatus />
            </Router>
        );
    }
    
}

export default App;